import { authenticationService } from "../../service/auth.service";

export const USER_INFO = "USER_INFO";

export function requestUserInfo() {
    return dispatch => {
        return dispatch({
            type: USER_INFO,
            payload: authenticationService.isAuthenticate() ? authenticationService.userInformation() : null
        });
    };
}