import React from "react";
import NavMenu from "./NavMenu";

const Navigation = () => {
    return (
        <aside id="left-panel">
            <NavMenu></NavMenu>
        </aside>
    );
};

export default Navigation;