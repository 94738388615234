import { config } from '../config/config';

const USER_LOCAL_STORAGE_NAME = 'user';
const SCHEME_LOCAL_STORAGE_NAME = 'scheme';
const REGION_LOCAL_STORAGE_NAME = 'region';

async function login(username, password, platform) {
    try {
        const entity = { username: username, password: password, platform: platform };
        const requestOptions = { method: 'POST', body: JSON.stringify(entity) };
        const response = await fetch(`${ config.apiUrl }/auth/login`, requestOptions);
        const data = await handleResponse(response);
        if (data) {
            data.picture = 'assets/img/avatars/sunny.png';
            localStorage.setItem(USER_LOCAL_STORAGE_NAME, JSON.stringify(data));
            //initial association
            const association = userInformation().user.associations[0];
            localStorage.setItem(SCHEME_LOCAL_STORAGE_NAME, JSON.stringify({ id: association.platform.id, name: association.platform.name }));
            localStorage.setItem(REGION_LOCAL_STORAGE_NAME, JSON.stringify({ id: association.region.id, name: association.region.name }));
        }
        return { status: 200, payload: data };
    } catch (error) {
        return { status: 503, message: error };
    }
}

async function reset(username) {
    try {
        const requestOptions = { method: 'PATCH' };
        const response = await fetch(`${ config.apiUrl }/auth/reset/${ username }`, requestOptions);
        return await handleResponse(response);
    } catch (error) {
        return error;
    }
}

async function valid(ticket) {

    try {
        const requestOptions = { method: 'GET' };
        const response = await fetch(`${ config.apiUrl }/auth/valid/${ ticket }`, requestOptions);
        return await handleResponse(response);
    } catch (error) {
        return error;
    }
}

async function set(ticket, password_tmp, password_new) {
    try {
        const entity = { ticket: ticket, password_tmp: password_tmp, password_new: password_new };
        const requestOptions = { method: 'POST', body: JSON.stringify(entity) };
        const response = await fetch(`${ config.apiUrl }/auth/set`, requestOptions);
        return await handleResponse(response);
    } catch (error) {
        return error;
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            if (response.status === 404) {
                const err = { message: data.message, status: 404 };
                return Promise.reject(err);
            } else {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
        }
        return data;
    });
}

function logout() {
    if (isAuthenticate()) {
        localStorage.removeItem(USER_LOCAL_STORAGE_NAME);
        localStorage.removeItem(SCHEME_LOCAL_STORAGE_NAME);
    }

}

function isAuthenticate() {
    return !!localStorage.getItem(USER_LOCAL_STORAGE_NAME);
}

function userInformation() {
    if (isAuthenticate()) {
        let user = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_NAME));
        user.scheme = scheme();
        user.region = region();
        return user;
    }
}

function scheme() {
    if (isAuthenticate()) {
        return JSON.parse(localStorage.getItem(SCHEME_LOCAL_STORAGE_NAME));
    }
}

function setScheme(scheme) {
    if (isAuthenticate()) {
        const { id, name } = scheme;
        localStorage.setItem(SCHEME_LOCAL_STORAGE_NAME, JSON.stringify({ id, name }));
        //update current region to match new scheme in the state container
        const regionData = userInformation().user.associations.find(data => data.platform.id === id ? true: false);
        setRegion(regionData.region);
    }
}

function region() {
    if (isAuthenticate()) {
        return JSON.parse(localStorage.getItem(REGION_LOCAL_STORAGE_NAME));
    }
}

function setRegion(region) {
    if (isAuthenticate()) {
        const { id, name } = region;
        localStorage.setItem(REGION_LOCAL_STORAGE_NAME, JSON.stringify({ id, name }));
    }
}

export const authenticationService = {
    login, logout, isAuthenticate, userInformation,
    reset, valid, set, scheme, setScheme, region, setRegion
};