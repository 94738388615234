import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Audit = Loadable({
    loader: () => import("./components/Audit"),
    loading: Loading
});


export const routes = [
    {
        path: "/audit",
        exact: true,
        component: Audit,
        name: "Audit"
    }

];
