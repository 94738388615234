import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Organization = Loadable({
  loader: () => import("./components/Organization"),
  loading: Loading,
});

export const routes = [
  {
    path: "/",
    exact: true,
    component: Organization,
    name: "Organizations",
  },
  {
    path: "/organizations",
    exact: true,
    component: Organization,
    name: "Organizations",
  },
];
