import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";
import TradeInspector from "./components/TradeInspector";

const GeneralReport = Loadable({
    loader: () => import("./components/GeneralReport"),
    loading: Loading
});


export const routes = [
    {
        path: "/reports/general",
        exact: true,
        component: GeneralReport,
        name: "General"
    },
    {
        path: "/reports/tradeInspector",
        exact: true,
        component: TradeInspector,
        name: "Trade Monitor"
    }

];
