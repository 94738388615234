import React, { useState, useEffect } from "react";
import { JarvisWidget } from "../../../common";
import { config } from "../../../config/config";
import reportService from '../../../service/report.service';
import Select from "react-select";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Loading from "../../../common/navigation/components/Loading";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import useDebounce from "../../../common/utils/components/use-debounce";

const Details = (props) => {
    const [coversData, setCoversData] = useState([]);
    const [shownData, setShownData] = useState([]);
    const [coversLoading, setCoversLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 200);
    const [selectedStatus, setSelectedStatus] = useState({ value: "", label: "ALL STATUS" });
    const available_status = [
        { value: "", label: "ALL STATUS" },
        { value: "FILLED", label: "FILLED" },
        { value: "REJECTED", label: "REJECTED" },
        { value: "CANCELED", label: "CANCELED" }];

    useEffect(() => {
        let isMounted = true;
        setCoversLoading(true);

        reportService.getCoversById(props.selectedOrderId).then(res => {
            if (isMounted) {
                setCoversData([...res]);
                setCoversLoading(false);
            }
        });
        return () => isMounted = false;
    }, [props.selectedOrderId]);

    useEffect(() => {
        let isMounted = true;
        const filteredCovers = filterCovers();
        if (isMounted){
            setShownData(filteredCovers);
        }
        return () => isMounted = false;
    }, [coversData, debouncedSearchValue, selectedStatus]);

    const filterCovers = () => {
        const objectToFilter = [...coversData];
        const dataFilteredByStatus = selectedStatus.value === '' ? [...objectToFilter]
            : objectToFilter.filter(cover => cover.status === selectedStatus.value);
        const filteredData = searchValue === '' ? [...dataFilteredByStatus]
            : dataFilteredByStatus.filter(el => {
                const values = Object.values(el);
                return values.some(v => v !== null ? v.toString().toUpperCase().includes(searchValue.toUpperCase()) : false);
            });
        return filteredData;
    };

    const covers_columns = [
        {
            Header: 'Client order id',
            accessor: 'client_order_id',
            width: 120,
            minResizeWidth: 120,
        },
        {
            Header: 'Orig. Client order id',
            accessor: 'orig_client_order_id',
            width: 120,
            minResizeWidth: 120,
        },
        {
            Header: 'Status',
            accessor: 'status',
            width: 85,
            minResizeWidth: 85,
            Cell: row => <p style={{ color: row.value === 'FILLED' ? 'green' : 'red' }}>  {row.value}</p>
        },
        {
            Header: 'Executed',
            accessor: 'modified',
            width: 90,
            minResizeWidth: 90,
            Cell: row => row.value.slice(11, 23)
        },
        {
            Header: 'Client',
            accessor: 'company_name',
            width: 120,
            minResizeWidth: 120,
        },
        {
            Header: 'Symbol',
            accessor: 'symbol',
            width: 75,
            minResizeWidth: 75,
        },
        {
            Header: 'Side',
            accessor: 'side',
            width: 50,
            minResizeWidth: 50,
        },
        {
            Header: 'Filled Amount',
            accessor: 'qty_filled_base',
            width: 90,
            minResizeWidth: 90,
            Cell: row => config.formatNumber(row.value)
        },
        {
            Header: 'Cover Rate',
            accessor: 'fill_price_final',
            width: 70,
            minResizeWidth: 70,
        },
        {
            Header: 'Req. Amount',
            accessor: 'qty_orig',
            width: 90,
            minResizeWidth: 90,
            Cell: row => config.formatNumber(row.value)
        },
        {
            Header: 'Hold. Time',
            accessor: 'holding_time',
            width: 65,
            minResizeWidth: 65,
        },
        {
            Header: 'Req. Rate',
            accessor: 'price_request',
            width: 70,
            minResizeWidth: 70,
        },
        {
            Header: 'Tenor',
            accessor: 'tenor',
            width: 50,
            minResizeWidth: 50,
        },
        {
            Header: 'Tier',
            accessor: 'tier',
            width: 50,
            minResizeWidth: 50,
        },
        {
            Header: 'Time in force',
            accessor: 'time_in_force',
            width: 60,
            minResizeWidth: 60,
        },
        {
            Header: 'Counterparty',
            accessor: 'source_name',
            width: 70,
            minResizeWidth: 70,
        },
        {
            Header: 'USD Volume',
            accessor: 'qty_usd',
            width: 90,
            minResizeWidth: 90,
            Cell: row => config.formatNumber(row.value)
        },
        {
            Header: 'Trade Date',
            accessor: 'trade_date',
            width: 80,
            minResizeWidth: 80,
            Cell: row => moment(row.value).format('YYYY-MM-DD')
        },
        {
            Header: 'Created',
            accessor: 'created',
            width: 150,
            minResizeWidth: 150,
            Cell: row => row.value.slice(0, 19)
        },
        {
            id: 'fwd_points',
            Header: 'Forward Points',
            accessor: 'fwd_points',
            minResizeWidth: 155,
            width: 155,
        },
        {
            id: 'spot_rate',
            Header: 'Spot Rate',
            accessor: 'spot_rate',
            minResizeWidth: 155,
            width: 155,
        },
    ];

    const tooltip = (
        <Tooltip id="tooltip">
            <strong>Filter covers.</strong>
        </Tooltip>
    );

    return (
        <JarvisWidget id="wid-id-0"
            colorbutton={false}
            deletebutton={false}
            editbutton={false}
            color="blueDark">
            <header>
                <h2> Covers {shownData.length > 0 ? `- ${shownData.length} records.` : ''} </h2>
            </header>
            <div>
                <div className="widget-body">
                    <fieldset style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div >
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                    <input style={{ marginLeft: "5px", textAlign: "center", width: '130px', marginRight: '6px' }}
                                        type="text" defaultValue={searchValue}
                                        onInput={e => setSearchValue(e.target.value)} placeholder="Search details" ></input>
                                </OverlayTrigger>
                            </div>
                            <div style={{ width: '140px' }}>
                                <Select
                                    options={available_status}
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(select) => {
                                        setSelectedStatus(select);
                                    }}
                                />
                            </div>
                        </div>
                    </fieldset>
                    {" "}
                    <div>
                        {!coversLoading ?
                            <ReactTable
                                filterable={false}
                                resizable={true}
                                defaultPageSize={75}
                                pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                                data={shownData}
                                columns={covers_columns}
                                className="-striped -highlight table table-responsive smart-form my-inspector-table"
                            /> : <Loading />}
                    </div>
                </div>
            </div>
        </JarvisWidget>
    );
}

export default Details;