import React, { useState, useEffect } from "react";
import { config } from "../../../config/config";
import styled from 'styled-components';
import LoginInfo from "./LoginInfo";
import SideNav, { Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Route } from "react-router-dom";
import { connect } from "react-redux";

//custom element to be used in sidenav to show the LoginInfo Component
const NavInfoPane = styled.div`
    float: left;
    width: 100%;
    padding: 4px;
    background-color: #1b1e24;
    color: #fff;
`;

const NavMenu = props => {

    //this method is used to ensure every item has an id.
    const addId = (item) => {
        if (item.items) {
            item.items = item.items.map(addId);
        }
        if (!item.id) {
            item.id = Math.random()
                .toString(36)
                .slice(2);
        }
        return item;
    }

    //getting all the Nav Items from navigation.js
    const allNavItems = require("../../../config/navigation.json").items.map(addId);
    //this method gets the permitted nav items basend on permissions of current platform and region
    const getPermittedNavItems = () => allNavItems.filter(item => {
        if (!item.permission)
            return true;
        return config.isAllowedTo(`${item.permission}:show`) || config.isAllowedTo(`${item.permission}:*`);
    });

    //defined state to be used with SideNav component
    const [expanded, setExpanded] = useState(true);
    const [navItems, setNavItems] = useState(getPermittedNavItems());

    //update Navigation options in sidenav when platform or region changes
    useEffect(() => {
        setNavItems(getPermittedNavItems());
    }, [props.scheme, props.region])

    //Toggle functionality updated to not use jquery
    const toggle = () => {
        setExpanded(!expanded);
        const bodyClasses = document.body.classList;
        if (!bodyClasses.contains('menu-on-top')) {
            bodyClasses.toggle('minified');
            bodyClasses.remove('hidden-menu');
        }
    };

    //Getting navigation list items
    const navList = navItems.map(item => {
        const ekey = item.route ? item.route : item.title;
        return (
            <NavItem eventKey={ekey} key={item.id}>
                <NavIcon>
                    <i className={item.icon} style={{ fontSize: '1.5em' }} />
                </NavIcon>
                <NavText style={{ fontSize: '.95em' }}>
                    {item.title} {item.items ? <b style={{ textAlign: 'right' }} className="collapse-sign"><i className="fa fa-chevron-circle-down"></i></b> : null}
                </NavText>
                {item.items ? //Getting child elements
                    item.items.map(childItem => {
                        return (
                            config.isAllowedTo(`${childItem.permission}:${childItem.action ? childItem.action : 'show'}`) || config.isAllowedTo(`${childItem.permission}:*`) ?
                                <NavItem eventKey={childItem.route} key={childItem.id}>
                                    <NavText>
                                        <i className={childItem.icon} style={{ fontSize: '1em', marginRight: '10px' }} />
                                        {childItem.title}
                                    </NavText>
                                </NavItem>
                                : null
                        );
                    })
                    : null
                }
            </NavItem>
        );
    });

    return (
        <Route render={({ location, history }) => (
            <SideNav
                expanded={expanded}
                onToggle={() => {
                    toggle();
                }}
                onSelect={(selected) => {
                    const to = `${selected}`;
                    // upgrading react-router fixed location not being updated
                    if (location.pathname !== to) {
                        history.push(to);
                    }
                }}
            >
                {expanded &&
                    <NavInfoPane>
                        <LoginInfo></LoginInfo>
                    </NavInfoPane>
                }
                <Nav defaultSelected={location.pathname}>
                    {navList}
                </Nav>
                <NavInfoPane>
                    <span className="minifyme" data-action="minifyMenu" onClick={toggle} style={{ padding: "3px" }}>
                        <i className="fa fa-arrow-circle-left hit" />
                    </span>
                </NavInfoPane>
            </SideNav>
        )} />
    );
};

const mapStateToProps = (state) => {
    return { ...state.scheme, ...state.region };
};

export default connect(mapStateToProps, null)(NavMenu);