import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Login = Loadable({
    loader: () => import("./components/Login"),
    loading: Loading
});

const Forgot = Loadable({
    loader: () => import("./components/Forgot"),
    loading: Loading
});

const Set = Loadable({
    loader: () => import("./components/Set"),
    loading: Loading
});

export const routes = [
    {
        path: "/login",
        exact: true,
        component: Login,
        name: "Login"
    },
    {
        path: "/login/:ticket",
        exact: true,
        component: Login,
        name: "Login"
    },
    {
        path: "/forgot",
        exact: true,
        component: Forgot,
        name: "Forgot"
    },
    {
        path: "/set/:ticket",
        exact: true,
        component: Set,
        name: "Set"
    }
];
