import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Settings = Loadable({
    loader: () => import("./components/Settings"),
    loading: Loading
});


export const routes = [
    {
        path: "/settings",
        exact: true,
        component: Settings,
        name: "Settings"
    }

];
