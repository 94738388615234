import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Administrator = Loadable({
    loader: () => import("./components/Administrator"),
    loading: Loading
});


export const routes = [
    {
        path: "/administrators",
        exact: true,
        component: Administrator,
        name: "Administrators"
    }

];
