import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const CreditCheck = Loadable({
    loader: () => import("./components/CreditCheck"),
    loading: Loading
});


export const routes = [
    {
        path: "/credit",
        exact: true,
        component: CreditCheck,
        name: "Credit Check"
    }

];
