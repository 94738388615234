import React, { useState, useEffect } from "react";
import ordersService from "../../../service/orders.service";
import { Loading } from "../../../common";

const OrderLogs = (props) => {

    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        ordersService.findLogsById(props.order_id)
            .then(data => {
                if (isMounted) {
                    setLogs([...data]);
                    setIsLoading(false);
                }
            })
            .catch(err => {
                if (isMounted) {
                    setLogs([]);
                    setIsLoading(false);
                }
                console.error('error loading logs', err);
            });
        return () => isMounted = false;
    }, [props.order_id]);

    const logText = logs.map((l, index) => {
        return (
            <p key={index}>{l.description}</p>
        )
    });

    return (
        !isLoading ?
            <div className="log-div">
                {logText.length > 0 ? logText : 'No logs were found for this order.'}
            </div> : <Loading />
    );
};

export default OrderLogs;