import React from "react";
import { connect } from "react-redux";
import { authenticationService } from "../../../service/auth.service";
import * as platformActions from "../../../store/actions/platform";

class LoginInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            scheme_selected: props.scheme ? { value: this.props.scheme.id, label: this.props.scheme.name }
                : { value: authenticationService.scheme().id, label: authenticationService.scheme().name }
        };
    }

    render() {
        const { user, picture } = this.state;
        return (
            <div className="login-info">
                <span>
                    <a href="#/">
                        <img src={ picture } alt="me" className="online"/>
                        <span className="font-lg bolder">{ user.username }</span>
                    </a>
                </span>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state.user, ...state.scheme };
};
export default connect(mapStateToProps, platformActions)(LoginInfo);
