import { authenticationService } from "../../service/auth.service";

export const SET_PLATFORM = "SET_PLATFORM";

export function requestPlatform() {
    return dispatch => {
        return dispatch({
            type: SET_PLATFORM,
            payload: authenticationService.isAuthenticate() ? authenticationService.scheme() : null
        });
    };
}