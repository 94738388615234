const check = (permissions, action, mod) => {
    const current_permissions = permissions.split(',');
    if (current_permissions.indexOf("all:*") >= 0) {
        return true;
    } else {
        const allModulePermission = mod + ':*';
        const actionModulePermission = mod + ':' + action;
        if (current_permissions.indexOf(allModulePermission) >= 0) {
            return true;
        } else {
            return current_permissions.indexOf(actionModulePermission) >= 0;
        }
    }
};
const Can = props =>
    check(props.permissions, props.action, props.module)
        ? props.yes()
        : props.no();

Can.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Can;