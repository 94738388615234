import { routes as auth } from "./views/auth";
import { routes as home } from "./views/home";
import { routes as administrator } from "./views/administrator";
import { routes as organization } from "./views/organization";
import { routes as streams } from './views/stream';
import { routes as switches } from './views/switches';
import { routes as reports } from './views/report';
import { routes as credit } from './views/credit-check';
import { routes as search } from './views/search';
import { routes as audit } from './views/audit';
import { routes as settings } from './views/settings';
import { routes as liquidity_providers } from './views/lp-administrator';
import { routes as rtns_rules } from "./views/rtns-rules";

export const routes = [
    ...home,
    ...administrator,
    ...organization,
    ...streams,
    ...switches,
    ...reports,
    ...credit,
    ...search,
    ...audit,
    ...settings,
    ...liquidity_providers,
    ...rtns_rules,
];

export const authRoutes = [
    ...auth
];