import { config } from '../config/config';

const serviceUrl = `${ config.apiUrl }/regions`;

async function findAll() {
    const requestOptions = { method: 'GET' };
    const res = await fetch(`${ serviceUrl }/`, requestOptions);
    return res.json();
}

async function findById(id) {
    const requestOptions = { method: 'GET' };
    const res = await fetch(`${ serviceUrl }/${ id }`, requestOptions);
    return res.json();
}

async function save(entity) {
    const requestOptions = { method: 'POST', body: JSON.stringify(entity) };
    const res = await fetch(`${ serviceUrl }/`, requestOptions);
    return res.json();
}

async function update(entity) {
    const requestOptions = { method: 'PUT', body: JSON.stringify(entity) };
    const res = await fetch(`${ serviceUrl }/${ entity.id }`, requestOptions);
    return res.json();
}

function remove(id) {
    const requestOptions = { method: 'DELETE' };
    return fetch(`${ serviceUrl }/${ id }`, requestOptions);
}

export default { findAll, findById, save, update, remove }