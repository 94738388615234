import React from "react";

export default function Loading() {
    return (
        <div className="text-center">
            <i
                className="fa fa-gear fa-spin fadeInOut"
                style={ {
                    fontSize: "38px",
                    opacity: 0.8,
                } }
            />
        </div>
    );
}
