import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Stream = Loadable({
    loader: () => import("./components/Stream"),
    loading: Loading
});


export const routes = [
    {
        path: "/liquidity/streams",
        exact: true,
        component: Stream,
        name: "Streams"
    }

];
