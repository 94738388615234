import React, { useState, useEffect, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import { Loading } from '../../../common';
import reportService from '../../../service/report.service';
import moment from 'moment';

const ApexPriceChart = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [pricesData, setPricesData] = useState([]);
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);
    const chartEl = useRef(null);
    const tsExec = new Date(props.selectedOrder.modified).getTime();
    const tsCreate = new Date(props.selectedOrder.created).getTime();
    const canceledStop = props.selectedOrder.status === 'CANCELED' && props.selectedOrder.order_type.includes('STOP_');

    const loadData = async () => {
        const pricesData = await reportService.getPriceTicks(props.selectedOrder.modified.slice(0, 19), props.selectedOrder.instrument_id);
        let minTs = '';
        let maxTs = '';
        //using a single loop instead of map and 2 reduce
        pricesData.forEach((val, i) => {
            const ts = new Date(val.time.replace('+0', '')).getTime();
            val.timestamp = ts;
            minTs = minTs === '' || ts < minTs ? ts : minTs;
            maxTs = maxTs === '' || ts > maxTs ? ts : maxTs;
        });

        return { pricesData, minTs, maxTs };
    }

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        loadData().then(data => {
            if (isMounted) {
                setPricesData(data.pricesData);
                setMinDate(new Date(data.minTs));
                setMaxDate(new Date(data.maxTs));
                setIsLoading(false);
                if (pricesData.length > 0 && props.selectedOrder.covering_rate === 0) chartEl.current.chart.toggleSeries('Cover Rate');
                if ((tsExec - tsCreate) > 60000) chartEl.current.chart.toggleSeries(canceledStop ? 'Stop Price' : 'Request Price');
            }
        });
        return () => isMounted = false;
    }, [props.selectedOrder]);


    const options = useMemo(() => {
        return {
            chart: {
                animations: {
                    enabled: false
                },
                stacked: false,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: false
                },
                toolbar: {
                    autoSelected: 'zoom'
                },
                //this option is needed in order for the svg and png download to have the dark theme background and look good.
                background: '#343F57'
            },
            colors: ['#0c9463', '#FF9800', '#FFD800', '#ee4540', '#2E93fA'],
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: [2, 2, 2, 2, 3],
                dashArray: 0,
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: [5, 5, 5, 0, 0],
                strokeWidth: 1.5,
            },
            title: {
                text: `Price movement for ${props.selectedOrder.symbol}`,
                align: 'center',
                style: {
                    cssClass: 'title-chart',
                    fontSize: '16px'
                }
            },
            theme: {
                mode: 'dark',
                palette: 'palette5'
            },
            yaxis: {
                title: {
                    text: 'Rate',
                    style: {
                        cssClass: 'title-chart'
                    }
                },
            },
            xaxis: {
                title: {
                    text: `Time: ${props.selectedOrder.modified.slice(0, 16)}`,
                    offsetX: -50,
                    style: {
                        cssClass: 'title-chart'
                    }
                },
                type: 'datetime',
                labels: {
                    formatter: (val) => moment(val).format('HH:mm:ss.SSS')
                }
            },
            tooltip: {
                shared: false,
                x: {
                    formatter: (val) => moment(val).format('HH:mm:ss.SSS')
                }
            },
            annotations: {
                position: 'back',
                xaxis: [{
                    x: tsExec,
                    strokeDashArray: 0,
                    borderColor: '#0c9463',
                    opacity: 1
                }],
                yaxis: [{
                    y: canceledStop ? props.selectedOrder.price_stop : props.selectedOrder.price_request,
                    strokeDashArray: 0,
                    borderColor: '#FEB019',
                    opacity: 1
                }]
            }
        }
    }, [props.selectedOrder]);
    const series = useMemo(() => [
        {
            name: 'Cover Rate',
            data: [[tsExec, props.selectedOrder.covering_rate]]
        },
        {
            name: 'Final Client Rate',
            data: [[tsExec, props.selectedOrder.status !== 'CANCELED' ? props.selectedOrder.fill_price_final
                : canceledStop ? props.selectedOrder.price_stop : props.selectedOrder.price_request]]
        },
        {
            name: canceledStop ? 'Stop Price' : 'Request Price',
            data: [[tsCreate, canceledStop ? props.selectedOrder.price_stop : props.selectedOrder.price_request]]
        },
        {
            name: 'Bid value',
            data: pricesData.map(val => [val.timestamp, val.bid])
        },
        {
            name: 'Offer value',
            data: pricesData.map(val => [val.timestamp, val.ask])
        }
    ], [pricesData]);

    return (
        !isLoading ?
            pricesData.length > 0 ?
                <div className="apexChart">
                    <Chart
                        id="my-my-chart"
                        options={options}
                        series={series}
                        type="line"
                        width="100%"
                        height="380"
                        ref={chartEl}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <label><b>Initial time: {minDate ? minDate.toLocaleTimeString() : ''}</b></label>
                        <label><b>Exec time: {props.selectedOrder.modified.slice(11, 23)}</b></label>
                        <label><b>Final time: {maxDate ? maxDate.toLocaleTimeString() : ''}</b></label>
                    </div>
                </div> :
                <div style={{ textAlign: 'center' }}>
                    <h2>No prices data found for this order.</h2>
                    <h4>Request Rate: {canceledStop ? props.selectedOrder.price_stop : props.selectedOrder.price_request}</h4><br />
                    <h4>Cover Rate: {props.selectedOrder.covering_rate}</h4><br />
                    <h4>Final Client Rate: {props.selectedOrder.status !== 'CANCELED' ? props.selectedOrder.fill_price_final
                        : canceledStop ? props.selectedOrder.price_stop : props.selectedOrder.price_request}</h4><br />
                </div>
            : <Loading />
    );
}

export default ApexPriceChart;