import React, { useState, useEffect } from "react";
import useDebounce from "../../../common/utils/components/use-debounce";
import Loading from "../../../common/navigation/components/Loading";
import reportService from '../../../service/report.service';
import { JarvisWidget } from "../../../common";
import { config } from "../../../config/config";
import { playAudio } from "../../../common/utils/functions/sound";
import Datepicker from "react-datepicker";
import ReactTable from "react-table";
import Select from "react-select";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.css"
import "react-table/react-table.css";

const Orders = (props) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [ordersData, setOrdersData] = useState([]);
    const [shownData, setShownData] = useState([]);
    const [ordersNotification, setOrdersNotification] = useState({});
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);
    const [selectedStatus, setSelectedStatus] = useState({ value: "", label: "ALL STATUS" });
    const available_status = [
        { value: "", label: "ALL STATUS" },
        { value: 'ACTIVE_ORDERS', label: 'ACTIVE ORDERS' },
        { value: 'CANCELED_BY_USER', label: 'CANCELED BY USER' },
        { value: 'EXPIRED', label: 'EXPIRED' },
        { value: 'FILLED', label: 'FILLED' },
        { value: 'MANUAL_TRADE', label: 'MANUAL TRADE' },
        { value: 'PARTIALLY_FILLED', label: 'PARTIALLY FILLED' },
        { value: 'PENDING_CANCEL', label: 'PENDING CANCEL' },
        { value: 'REJECTED', label: 'REJECTED' },
        { value: 'SWITCH', label: 'SWITCH' }
    ];

    function formatDate(date) {
        var day = '' + date.getDate();
        var month = '' + (date.getMonth() + 1);
        var year = date.getFullYear();
        day = day.length === 1 ? `0${day}` : day;
        month = month.length === 1 ? `0${month}` : month;
        return `${year}-${month}-${day}`;
    }

    const handleStartDateChange = (date) => {
        props.setSelectedOrder(undefined);
        setStartDate(date);
    }

    const handleEndDateChange = (date) => {
        props.setSelectedOrder(undefined);
        setEndDate(date);
    }

    useEffect(() => {
        props.setOrdersLoaded(false);
    }, [startDate, endDate, props.region, props.platform]);

    useEffect(() => {
        props.setSelectedOrder(undefined);
    }, [props.region, props.platform]);

    useEffect(() => {
        let isMounted = true;
        reportService.getGeneralReport(formatDate(startDate), formatDate(endDate), [props.region.name]).then(async response => {
            if (isMounted) {
                setOrdersData([...response]);
                props.setOrdersLoading(false);
                props.setOrdersLoaded(true);
                let objNotifications = {};
                for await (let obj of response) {
                    objNotifications[obj.id_orders] = false;
                }
                setOrdersNotification(objNotifications);
            }
        });
        return () => isMounted = false;
    }, [startDate, endDate, props.region, props.platform, props.update]);

    useEffect(() => {
        let isMounted = true;
        const intervalId = setInterval(() => {
            props.setOrdersLoading(true);
            reportService.getGeneralReport(formatDate(startDate), formatDate(endDate), [props.region.name]).then(response => {
                if (isMounted) {
                    setOrdersData([...response]);
                    props.setOrdersLoading(false);
                    props.setOrdersLoaded(true);
                }
            });
        }, props.delay * 1000);
        return () => {
            isMounted = false;
            clearInterval(intervalId);
        }
    }, [props.delay, startDate, endDate, props.region, props.platform]);

    const notificationProcess = async () => {
        const sizeNotification = Object.keys(ordersNotification).length;
        if (sizeNotification > 0 && ordersData.length !== sizeNotification) {
            let newOrders = {...ordersNotification};
            for await (let orderToFind of ordersData) {
                const { id_orders, final_status } = orderToFind;
                if(!ordersNotification.hasOwnProperty(`${id_orders}`)){
                    if (final_status === 'REJECTED') {
                        playAudio('notify-error');
                    } else {
                        playAudio('notify');
                    }
                    newOrders[id_orders] = false;
                }
            }
            setOrdersNotification(newOrders);
        }
    }

    useEffect(() => {
        let isMounted = true;
        const filteredOrders = filterOrders();
        notificationProcess();
        if (isMounted)
            setShownData(filteredOrders);
        return () => isMounted = false;
    }, [ordersData, debouncedSearchValue, selectedStatus]);

    const filterOrders = () => {
        const objectToFilter = [...ordersData];
        const dataFilteredByStatus = selectedStatus.value === '' ? [...objectToFilter]
            : objectToFilter.filter(order => order.final_status === selectedStatus.value);
        const filteredData = searchValue === '' ? [...dataFilteredByStatus]
            : dataFilteredByStatus.filter(el => {
                const values = Object.values(el);
                return values.some(v => v !== null ? v.toString().toUpperCase().includes(searchValue.toUpperCase()) : false);
            });
        return filteredData;
    };

    const orders_columns = [
        {
            id: 'client_order_id',
            Header: 'Client order id',
            accessor: 'client_order_id',
            minResizeWidth: 155,
            width: 155,
        },
        {
            id: 'final_status',
            Header: 'Status',
            accessor: 'final_status',
            minResizeWidth: 85,
            width: 85,
            Cell: row => {
                let colorCurrent = '';
                const value = row.value;
                switch (value) {
                    case 'ACTIVE_ORDERS':
                        colorCurrent = 'gray';
                        break;
                    case 'CANCELED_BY_USER':
                        colorCurrent = 'red';
                        break;
                    case 'EXPIRED':
                        colorCurrent = 'red';
                        break;
                    case 'FILLED':
                        colorCurrent = 'green';
                        break;
                    case 'MANUAL_TRADE':
                        colorCurrent = 'green';
                        break;
                    case 'PARTIALLY_FILLED':
                        colorCurrent = '#f9a825';
                        break;
                    case 'PENDING_CANCEL':
                        colorCurrent = 'red';
                        break;
                    case 'REJECTED':
                        colorCurrent = 'red';
                        break;
                    case 'SWITCH':
                        colorCurrent = 'green';
                        break;
                    default:
                        colorCurrent = 'gray';
                }

                const style = { color: colorCurrent };

                if (value === 'ACTIVE_ORDERS') return  <b style={style}>{value}</b>

                return <p style={style}>  {row.value}</p>
            }
        },
        {
            id: 'modified',
            Header: 'Executed',
            accessor: 'modified',
            minResizeWidth: 90,
            width: 90,
            Cell: row => row.value.slice(11, 23)
        },
        {
            id: 'company_name',
            Header: 'Organization',
            accessor: 'company_name',
            minResizeWidth: 120,
            width: 120,
        },
        {
            id: 'symbol',
            Header: 'Symbol',
            accessor: 'symbol',
            minResizeWidth: 75,
            width: 75,
        },
        {
            id: 'side',
            Header: 'Side',
            accessor: 'side',
            minResizeWidth: 50,
            width: 50,
        },
        {
            id: 'qty_filled_base',
            Header: 'Filled Amount',
            accessor: 'qty_filled_base',
            minResizeWidth: 90,
            width: 90,
            Cell: row => config.formatNumber(row.value)
        },
        {
            id: 'fill_price_final',
            Header: 'Final Client Rate',
            accessor: 'fill_price_final',
            minResizeWidth: 70,
            width: 70,
        },
        {
            id: 'qty_orig',
            Header: 'Req. Amount',
            accessor: 'qty_orig',
            minResizeWidth: 90,
            width: 90,
            Cell: row => config.formatNumber(row.value)
        },
        {
            id: 'price_request',
            Header: 'Req. Rate',
            accessor: 'price_request',
            minResizeWidth: 70,
            width: 70,
        },
        {
            id: 'price_stop',
            Header: 'Stop Rate',
            accessor: 'price_stop',
            minResizeWidth: 70,
            width: 70,
            show: shownData.some(el => el.price_stop),
        },
        {
            id: 'tenor',
            Header: 'Tenor',
            accessor: 'tenor',
            minResizeWidth: 50,
            width: 50,
        },
        {
            id: 'order_type',
            Header: 'Order Type',
            accessor: 'order_type',
            minResizeWidth: 80,
            width: 80,
        },
        {
            id: 'time_in_force',
            Header: 'Time in force',
            accessor: 'time_in_force',
            minResizeWidth: 80,
            width: 80,
        },
        {
            id: 'user_name',
            Header: 'Username',
            accessor: 'user_name',
            minResizeWidth: 85,
            width: 85,
        },
        {
            id: 'trade_date',
            Header: 'Trade Date',
            accessor: 'trade_date',
            minResizeWidth: 80,
            width: 80,
            Cell: row => moment(row.value).format('YYYY-MM-DD')
        },
        {
            id: 'created',
            Header: 'Created',
            accessor: 'created',
            minResizeWidth: 150,
            width: 150,
            Cell: row => row.value.slice(0, 19)
        },
        {
            id: 'fwd_points',
            Header: 'Forward Points',
            accessor: 'fwd_points',
            minResizeWidth: 155,
            width: 155,
        },
        {
            id: 'spot_rate',
            Header: 'Spot Rate',
            accessor: 'spot_rate',
            minResizeWidth: 155,
            width: 155,
        },
    ];

    const tooltip = (
        <Tooltip id="tooltip">
            <strong>Filter orders.</strong>
        </Tooltip>
    );

    return (
        <JarvisWidget id="wid-id-0"
            colorbutton={false}
            deletebutton={false}
            editbutton={false}
            color="blueDark">
            <header>
                <h2> Orders {shownData.length > 0 ? `- ${shownData.length} records.` : ''} </h2>
            </header>
            <div>
                <div className="widget-body">
                    <fieldset style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div >
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                    <input style={{ marginLeft: "5px", textAlign: "center", width: '130px', marginRight: '6px' }}
                                        type="text" defaultValue={searchValue}
                                        onInput={e => setSearchValue(e.target.value)} placeholder="Search orders" ></input>
                                </OverlayTrigger>

                            </div>
                            <div>
                                <Datepicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    maxDate={endDate}
                                />
                                <label style={{ marginRight: '6px', marginLeft: '6px' }}><b>to:</b></label>
                                <Datepicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    minDate={startDate}
                                    maxDate={tomorrow}
                                />
                            </div>
                            <div style={{ width: '140px' }}>
                                <Select
                                    options={available_status}
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(select) => {
                                        setSelectedStatus(select);
                                    }}
                                />
                            </div>
                        </div>
                    </fieldset>
                    {" "}
                    <div>
                        {props.ordersLoaded ?
                            <ReactTable
                                filterable={false}
                                resizable={true}
                                defaultPageSize={75}
                                pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                                data={shownData}
                                columns={orders_columns}
                                className="-striped -highlight table table-responsive smart-form my-inspector-table"
                                getTrProps={(trprops, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        return {
                                            onClick: (e) => {
                                                const rowOrderId = rowInfo.original.id_orders;
                                                props.selectedOrder && rowOrderId === props.selectedOrder.id_orders ? props.setSelectedOrder(undefined)
                                                    : props.setSelectedOrder(rowInfo.original);
                                            },
                                            style: {
                                                background: props.selectedOrder && rowInfo.original.id_orders === props.selectedOrder.id_orders ? '#353D4B' : '',
                                                color: props.selectedOrder && rowInfo.original.id_orders === props.selectedOrder.id_orders ? 'white' : ''
                                            }
                                        }
                                    } else {
                                        return {}
                                    }
                                }}
                            /> : <Loading />}
                    </div>
                </div>
            </div>
        </JarvisWidget>
    );
}

export default Orders;