import React from "react";

const Footer = () =>
    (
        <div className="page-footer">
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <span className="txt-color-white">
                        Edgewater FX Admin © {new Date().getFullYear()}
                    </span>
                </div>

                <div className="col-xs-6 col-sm-6 text-right hidden-xs">
                    <div className="txt-color-white inline-block">
                        <i className="txt-color-blueLight hidden-mobile">
                            <strong>{new Date().toDateString()} &nbsp;</strong>
                        </i>
                    </div>
                </div>
            </div>
        </div>
    );

export default Footer;