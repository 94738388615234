import { config } from '../config/config';
import { authenticationService } from "./auth.service";
import moment from "moment";
import regionService from './region.service';

const serviceUrl = `${config.apiUrl}/reports/`;

const getRegionString = async (regions) => {
    //fetch data from all regions
    if (regions.includes('all'))
        return 'ALL';

    //we get all the regions and filter the ones that automatically should include others
    //example: "mexico:*" should bring "mexico:monterrery" and "mexico:mexico_city"
    const allRegions = (await regionService.findAll()).map(region => region.name);
    const topRegions = allRegions.filter(region => region.includes(':*'));

    topRegions.forEach(element => {
        //if the original regions array has a "top region" then add the corresponding regions
        if (regions.includes(element)) {
            const regionsToAdd = allRegions.filter(r => r.startsWith(element.slice(0, element.length - 1)) && r !== element);
            regions = [...regions, ...regionsToAdd];
        }
    });

    let regionstring = '(';
    regions.forEach(element => {
        regionstring += `"${element}",`
    });
    regionstring = regionstring.slice(0, regionstring.length - 1)
    regionstring += ')';
    return regionstring;
}

async function getGeneralReport(start_date, end_date, region) {
    const regionstring = await getRegionString(region);
    const requestOptions = { method: 'GET' };
    const url = `${serviceUrl}${authenticationService.scheme().name}/general/${start_date}/${end_date}/${regionstring}`;
    const res = await fetch(`${url}`, requestOptions);

    return res.json();
}

async function getCoversById(orderId) {
    const requestOptions = { method: 'GET' };
    const url = `${serviceUrl}${authenticationService.scheme().name}/covers/${orderId}`;
    const res = await fetch(`${url}`, requestOptions);

    return res.json();
}

async function getPriceTicks(exec_date_time, pairId) {
    const requestOptions = { method: 'GET' };
    const url = `${serviceUrl}ticks/${exec_date_time}/${pairId}`;
    const res = await fetch(`${url}`, requestOptions);

    return res.json();
}

async function getVolumeReport(start_date, end_date, regions) {
    const regionstring = await getRegionString(regions);

    const requestOptions = { method: 'GET' };
    const url = `${serviceUrl}${authenticationService.scheme().name}/volume/${start_date}/${end_date}/${regionstring}`;
    const res = await fetch(`${url}`, requestOptions);

    return res.json();
}

async function getDailyReport(regions) {
    const start_date = moment().format('YYYY-MM-DD');
    const end_date = moment().add(1, 'day').format('YYYY-MM-DD');
    const regionstring = await getRegionString(regions);

    const requestOptions = { method: 'GET' };
    const url = `${serviceUrl}${authenticationService.scheme().name}/volume/${start_date}/${end_date}/${regionstring}`;
    const res = await fetch(`${url}`, requestOptions);
    const total = await res.json();
    return total[0] ? total[0].qty_usd : 0;
}

async function getFillRatioReport(regions) {
    const start_date = moment().format('YYYY-MM-DD');
    const end_date = moment().add(1, 'day').format('YYYY-MM-DD');
    const regionstring = await getRegionString(regions);

    const requestOptions = { method: 'GET' };
    const url = `${serviceUrl}${authenticationService.scheme().name}/ratio/${start_date}/${end_date}/${regionstring}`;
    const res = await fetch(`${url}`, requestOptions);

    return res.json();
}

async function getMonthlyVolumeReport(start_date, end_date, regions) {
    const regionstring = await getRegionString(regions);

    const requestOptions = { method: 'GET' };
    const url = `${serviceUrl}${authenticationService.scheme().name}/monthly/${start_date}/${end_date}/${regionstring}`;
    const res = await fetch(`${url}`, requestOptions);

    return res.json();
}

export default { getGeneralReport, getCoversById, getPriceTicks, getVolumeReport, getDailyReport, getFillRatioReport, getMonthlyVolumeReport }