import { SET_PLATFORM } from "../actions";

const initialState = {
    scheme: null,
};

export function platformReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PLATFORM:
            return {
                ...state,
                scheme: action.payload
            };
        default:
            return state;
    }
}
