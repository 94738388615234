import React, { useState } from "react";
import { BigBreadcrumbs, WidgetGrid } from "../../../common";
import { config } from "../../../config/config";
import { connect } from "react-redux";
import Can from "../../../permissions/Can";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/lib/Button";
import Orders from "./Orders";
import Details from "./Details";
import ApexPriceChart from "./ApexPriceChart";
import OrderLogs from "./OrderLogs";

const TradeInspector = (props) => {

    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [ordersLoading, setOrdersLoading] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(undefined);
    const [update, setUpdate] = useState(false);
    const [delay, setDelay] = useState(10);
    const delayOptions = [5, 10, 30, 60];
    const handleChangeInterval = (event) => {
        setDelay(event.target.value);
    }

    const { permissions } = config.isAllowed();

    return (
        <Can permissions={permissions}
            module="manage:reports"
            action="trade_inspector"
            yes={() => (
                <div id="content">
                    <div className="row">
                        <div className="col-xs-12 col-sm-7 col-md-7 col-lg-4 big-breadcrumbs">
                            <BigBreadcrumbs
                                items={["Trade Inspector"]}
                                icon="fa fa-tv"
                                className="col-xs-12 col-sm-7 col-md-7 col-lg-6"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-6" />
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-2">
                            <div className="row">
                                <div className="col-xs-8 col-sm-8 col-md-8 col-lg-3" />
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-5">
                                    <div className="pull-right">
                                        <label className="">
                                            <select className="input-lg form-control"
                                                name="delay"
                                                value={delay}
                                                onChange={handleChangeInterval}>
                                                {delayOptions.map((element, idx) => {
                                                    return (
                                                        <option key={idx} value={element}>
                                                            {element}s
                                                             </option>)
                                                })}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-4">
                                    <div className="pull-right">
                                        <OverlayTrigger placement="bottom"
                                            overlay={<Tooltip
                                                id="edit-tooltip">Reload</Tooltip>}>
                                            <Button type="button" className="btn-lg"
                                                onClick={() => setUpdate(!update)}>
                                                {ordersLoading
                                                    ? <i className="fa fa-fw fa-spin fa-refresh" />
                                                    : <i className="fa fa-fw fa-refresh" />
                                                }
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <WidgetGrid>
                        <div className="row">
                            <article className="col-xs-12 col-sm-12 col-md-12 col-lg-6" style={{ marginBottom: '-15px' }}>
                                <Orders
                                    delay={delay}
                                    selectedOrder={selectedOrder}
                                    setSelectedOrder={(order) => {
                                        setSelectedOrder(order);
                                    }}
                                    setOrdersLoading={(value) => {
                                        setOrdersLoading(value);
                                    }}
                                    ordersLoaded={ordersLoaded}
                                    setOrdersLoaded={(value) => {
                                        setOrdersLoaded(value);
                                    }}
                                    region={props.region}
                                    platform={props.platform}
                                    update={update} />
                            </article >
                            <article className="col-xs-12 col-sm-12 col-md-12 col-lg-6" style={{ marginBottom: '-15px' }}>
                                <Details
                                    selectedOrderId={selectedOrder ? selectedOrder.id_orders : undefined}
                                />
                            </article >
                        </div >
                        {" "}
                        <div className="row" style={{ marginBottom: '50px' }}>
                            <article className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                {selectedOrder ?
                                    <OrderLogs
                                        order_id={selectedOrder ? selectedOrder.client_order_id : undefined}
                                    />
                                    : null}
                            </article>
                            <article className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                {selectedOrder ?
                                    <ApexPriceChart
                                    selectedOrder={selectedOrder ? selectedOrder : ''} />
                                    : null}
                            </article>
                        </div>
                    </WidgetGrid >
                </div >
            )} />
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        scheme: state.scheme.scheme,
        region: state.region.region
    };
};
export default connect(mapStateToProps)(TradeInspector);