import React from 'react';
import { Redirect, Route } from 'react-router-dom'
import { authenticationService } from '../service/auth.service'

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route exact { ...rest } render={ props => (
        authenticationService.isAuthenticate()
            ? <Component { ...props } />
            : <Redirect to={ { pathname: '/login', state: { from: props.location } } }/>
    ) }/>
);