import $ from "jquery";
import { authenticationService } from "../service/auth.service";
import fetchIntercept from "fetch-intercept";

const MextradeAdminConfig = {
  menu_speed: 200,
  smartSkin: "smart-style-1",
  apiRootUrl: "assets/api",
  buildUrl: "build",
  skins: [
    {
      name: "smart-style-0",
      logo: "assets/img/logo.png",
      class: "btn btn-block btn-xs txt-color-white margin-right-5",
      style: {
        backgroundColor: "#4E463F",
      },
      label: "Smart Default",
    },

    {
      name: "smart-style-1",
      logo: "assets/img/ewm_admin.png",
      class: "btn btn-block btn-xs txt-color-white",
      style: {
        background: "#3A4558",
      },
      label: "Dark Elegance",
    },

    {
      name: "smart-style-2",
      logo: "assets/img/logo-blue.png",
      class: "btn btn-xs btn-block txt-color-darken margin-top-5",
      style: {
        background: "#fff",
      },
      label: "Ultra Light",
    },

    {
      name: "smart-style-3",
      logo: "assets/img/logo-pale.png",
      class: "btn btn-xs btn-block txt-color-white margin-top-5",
      style: {
        background: "#f78c40",
      },
      label: "Google Skin",
    },

    {
      name: "smart-style-4",
      logo: "assets/img/logo-pale.png",
      class: "btn btn-xs btn-block txt-color-white margin-top-5",
      style: {
        background: "#bbc0cf",
        border: "1px solid #59779E",
        color: "#17273D !important",
      },
      label: "PixelSmash",
    },

    {
      name: "smart-style-5",
      logo: "assets/img/logo-pale.png",
      class: "btn btn-xs btn-block txt-color-white margin-top-5",
      style: {
        background: "rgba(153, 179, 204, 0.2)",
        border: "1px solid rgba(121, 161, 221, 0.8)",
        color: "#17273D !important",
      },
      label: "Glass",
    },

    {
      name: "smart-style-6",
      logo: "assets/img/logo-pale.png",
      class: "btn btn-xs btn-block txt-color-white margin-top-5",
      style: {
        background: "rgba(153, 179, 204, 0.2)",
        border: "1px solid rgba(121, 161, 221, 0.8)",
        color: "#17273D !important",
      },
      beta: true,
      label: "MaterialDesign",
    },
  ],
};
MextradeAdminConfig.sound_path = "assets/sound/";
MextradeAdminConfig.sound_on = true;
MextradeAdminConfig.debugState = true;
MextradeAdminConfig.debugStyle = "font-weight: bold; color: #00f;";
MextradeAdminConfig.debugStyle_green =
  "font-weight: bold; font-style:italic; color: #46C246;";
MextradeAdminConfig.debugStyle_red = "font-weight: bold; color: #ed1c24;";
MextradeAdminConfig.debugStyle_warning = "background-color:yellow";
MextradeAdminConfig.debugStyle_success =
  "background-color:green; font-weight:bold; color:#fff;";
MextradeAdminConfig.debugStyle_error =
  "background-color:#ed1c24; font-weight:bold; color:#fff;";
MextradeAdminConfig.voice_command = false;
MextradeAdminConfig.voice_command_auto = false;

/*
 *  Sets the language to the default 'en-US'. (supports over 50 languages
 *  by google)
 *
 *  Afrikaans         ['af-ZA']
 *  Bahasa Indonesia  ['id-ID']
 *  Bahasa Melayu     ['ms-MY']
 *  CatalГ            ['ca-ES']
 *  ДЊeЕЎtina         ['cs-CZ']
 *  Deutsch           ['de-DE']
 *  English           ['en-AU', 'Australia']
 *                    ['en-CA', 'Canada']
 *                    ['en-IN', 'India']
 *                    ['en-NZ', 'New Zealand']
 *                    ['en-ZA', 'South Africa']
 *                    ['en-GB', 'United Kingdom']
 *                    ['en-US', 'United States']
 *  EspaГ±ol          ['es-AR', 'Argentina']
 *                    ['es-BO', 'Bolivia']
 *                    ['es-CL', 'Chile']
 *                    ['es-CO', 'Colombia']
 *                    ['es-CR', 'Costa Rica']
 *                    ['es-EC', 'Ecuador']
 *                    ['es-SV', 'El Salvador']
 *                    ['es-ES', 'EspaГ±a']
 *                    ['es-US', 'Estados Unidos']
 *                    ['es-GT', 'Guatemala']
 *                    ['es-HN', 'Honduras']
 *                    ['es-MX', 'MГ©xico']
 *                    ['es-NI', 'Nicaragua']
 *                    ['es-PA', 'PanamГЎ']
 *                    ['es-PY', 'Paraguay']
 *                    ['es-PE', 'PerГє']
 *                    ['es-PR', 'Puerto Rico']
 *                    ['es-DO', 'RepГєblica Dominicana']
 *                    ['es-UY', 'Uruguay']
 *                    ['es-VE', 'Venezuela']
 *  Euskara           ['eu-ES']
 *  FranГ§ais         ['fr-FR']
 *  Galego            ['gl-ES']
 *  Hrvatski          ['hr_HR']
 *  IsiZulu           ['zu-ZA']
 *  ГЌslenska         ['is-IS']
 *  Italiano          ['it-IT', 'Italia']
 *                    ['it-CH', 'Svizzera']
 *  Magyar            ['hu-HU']
 *  Nederlands        ['nl-NL']
 *  Norsk bokmГҐl     ['nb-NO']
 *  Polski            ['pl-PL']
 *  PortuguГЄs        ['pt-BR', 'Brasil']
 *                    ['pt-PT', 'Portugal']
 *  RomГўnДѓ          ['ro-RO']
 *  SlovenДЌina       ['sk-SK']
 *  Suomi             ['fi-FI']
 *  Svenska           ['sv-SE']
 *  TГјrkГ§e          ['tr-TR']
 *  Р±СЉР»РіР°СЂСЃРєРё['bg-BG']
 *  PСѓСЃСЃРєРёР№     ['ru-RU']
 *  РЎСЂРїСЃРєРё      ['sr-RS']
 *  н•њкµ­м–ґ         ['ko-KR']
 *  дё­ж–‡            ['cmn-Hans-CN', 'ж™®йЂљиЇќ (дё­е›Ѕе¤§й™†)']
 *                    ['cmn-Hans-HK', 'ж™®йЂљиЇќ (й¦™жёЇ)']
 *                    ['cmn-Hant-TW', 'дё­ж–‡ (еЏ°зЃЈ)']
 *                    ['yue-Hant-HK', 'зІµиЄћ (й¦™жёЇ)']
 *  ж—Ґжњ¬иЄћ         ['ja-JP']
 *  Lingua latД«na    ['la']
 */
MextradeAdminConfig.voice_command_lang = "en-US";
/*
 *  Use localstorage to remember on/off (best used with HTML Version)
 */
MextradeAdminConfig.voice_localStorage = false;
/*
 * Voice Commands
 * Defines all voice command variables and functions
 */
if (MextradeAdminConfig.voice_command) {
  MextradeAdminConfig.commands = {
    "show dashboard": {
      type: "navigate",
      payload: "/dashboard/analytics",
    },
    "show social": {
      type: "navigate",
      payload: "/dashboard/social-wall",
    },
    "show outlook": {
      type: "navigate",
      payload: "/outlook",
    },
    "show graphs": {
      type: "navigate",
      payload: "/graphs/chart-js",
    },
    "show flot chart": {
      type: "navigate",
      payload: "/graphs/flot-charts",
    },
    "show morris chart": {
      type: "navigate",
      payload: "/graphs/morris-charts",
    },
    "show inline chart": {
      type: "navigate",
      payload: "/graphs/sparklines",
    },
    "show dygraphs": {
      type: "navigate",
      payload: "/graphs/dygraphs",
    },
    "show tables": {
      type: "navigate",
      payload: "/tables/normal",
    },
    "show data table": {
      type: "navigate",
      payload: "/tables/datatables",
    },
    "show form": {
      type: "navigate",
      payload: "/forms/elements",
    },
    "show form layouts": {
      type: "navigate",
      payload: "/forms/layouts",
    },
    "show form validation": {
      type: "navigate",
      payload: "/forms/validation",
    },
    "show form elements": {
      type: "navigate",
      payload: "/forms/bootstrap-elements",
    },
    "show form plugins": {
      type: "navigate",
      payload: "/forms/plugins",
    },
    "show form wizards": {
      type: "navigate",
      payload: "/forms/wizards",
    },
    "show bootstrap editor": {
      type: "navigate",
      payload: "/forms/editors",
    },
    "show dropzone": {
      type: "navigate",
      payload: "/forms/dropzone",
    },
    "show image cropping": {
      type: "navigate",
      payload: "/forms/image-cropping",
    },
    "show general elements": {
      type: "navigate",
      payload: "/ui/general-elements",
    },
    "show buttons": {
      type: "navigate",
      payload: "/ui/buttons",
    },
    "show fontawesome": {
      type: "navigate",
      payload: "/ui/icons/font-awesome",
    },
    "show glyph icons": {
      type: "navigate",
      payload: "/ui/icons/glyphicons",
    },
    "show flags": {
      type: "navigate",
      payload: "/ui/icons/flags",
    },
    "show grid": {
      type: "navigate",
      payload: "/ui/grid",
    },
    "show tree view": {
      type: "navigate",
      payload: "/ui/treeviews",
    },
    "show nestable lists": {
      type: "navigate",
      payload: "/ui/nestable-lists",
    },
    "show jquery U I": {
      type: "navigate",
      payload: "/ui/jquery-ui",
    },
    "show typography": {
      type: "navigate",
      payload: "/ui/typography",
    },
    "show calendar": {
      type: "navigate",
      payload: "/calendar",
    },
    "show widgets": {
      type: "navigate",
      payload: "/widgets",
    },
    "show gallery": {
      type: "navigate",
      payload: "/app-views/gallery",
    },
    "show maps": {
      type: "navigate",
      payload: "/maps",
    },
    "go back": () => {
      window.history.back(1);
    },
    "scroll up": () => {
      $("html, body").animate({ scrollTop: 0 }, 100);
    },
    "scroll down": () => {
      $("html, body").animate({ scrollTop: $(document).height() }, 100);
    },
    "hide navigation": {
      type: "layout",
      payload: "hide navigation",
    },
    "show navigation": {
      type: "layout",
      payload: "show navigation",
    },
    mute: {
      type: "sound",
      payload: "mute",
    },
    "sound on": {
      type: "sound",
      payload: "sound on",
    },
    stop: {
      type: "voice",
      payload: "stop",
    },
    help: {
      type: "voice",
      payload: "help on",
    },
    "got it": {
      type: "voice",
      payload: "help off",
    },
    logout: {
      type: "navigate",
      payload: "/auth/login",
    },
  };
}
/*
 * Default Configuration
 */
MextradeAdminConfig.fixedHeader = true;
MextradeAdminConfig.fixedNavigation = false;
MextradeAdminConfig.fixedRibbon = true;
MextradeAdminConfig.fixedFooter = true;
MextradeAdminConfig.apiUrl = "/api";
MextradeAdminConfig.buildHeaders = function(url) {
  const headers = {};
  headers["Content-Type"] = "application/json";
  if (authenticationService.isAuthenticate()) {
    headers["Authorization"] =
      "Bearer " + authenticationService.userInformation().token;
  }
  return headers;
};
MextradeAdminConfig.message = function(key) {
  switch (key) {
    case "PASSWORD_ERROR":
      return {
        title: "Error",
        content:
          "The password should have between 8 and 16 characters, should contain at least one digit, one upper case and one lowercase",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "AUTHENTICATION_ERROR":
      return {
        title: "Error",
        content: "You don't have permission",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "INVALID_PLATFORM":
      return {
        title: "Error",
        content: "Invalid platform",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "INVALID_INFORMATION":
      return {
        title: "Error",
        content: "The information is not valid, please review",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "MAIL_SENT":
      return {
        title: "Information",
        content: "Mail sent to the user",
        color: "#739E73",
        icon: "fa fa-check swing animated",
        timeout: 6000,
      };
    case "PASSWORD_SET":
      return {
        title: "Information",
        content: "User password has been set",
        color: "#739E73",
        icon: "fa fa-check swing animated",
        timeout: 6000,
      };
    case "USER_OR_PASSWORD_INVALID":
      return {
        title: "Error",
        content: "User or password invalid",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "ENTITY_DOES_NOT_EXIST":
      return {
        title: "Error",
        content: "This entity does not exist",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "ENTITY_ALREADY_EXIST":
      return {
        title: "Error",
        content: "There is another registry with the same values",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "ENTITY_CREATED":
      return {
        title: "Information",
        content: "Record was saved",
        color: "#739E73",
        icon: "fa fa-check swing animated",
        timeout: 6000,
      };
    case "ENTITY_REMOVED":
      return {
        title: "Information",
        content: "Record was removed",
        color: "#739E73",
        icon: "fa fa-check swing animated",
        timeout: 6000,
      };
    case "ENTITY_UPDATED":
      return {
        title: "Information",
        content: "Record was updated",
        color: "#739E73",
        icon: "fa fa-check swing animated",
        timeout: 6000,
      };
    case "SERVICE_ERROR":
      return {
        title: "Error",
        content: "Service has an error, please check the webserver",
        color: "#C46A69",
        icon: "fa fa-times swing animated",
        timeout: 6000,
      };
    case "PASSWORD_NOT_SET":
      return {
        title: "Warning",
        content: "You need to change you password.",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "NO_MORE_SPREADS":
      return {
        title: "Warning",
        content: "You cannot add more spreads.",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "ENTITY_NOT_UPDATED":
      return {
        title: "Warning",
        content: "Entity was not update",
        color: "#C46A69",
        icon: "fa fa-warning swing animated",
        timeout: 6000,
      };
    case "EMPTY_DATA":
      return {
        title: "Information",
        content: "The information is empty",
        color: "#296191",
        icon: "fa fa-info swing animated",
        timeout: 6000,
      };
    default:
      return {
        title: "Information",
        content: `The key '${key}' does not have a message to show, please add it.`,
        icon: "fa fa-question swing animated",
        timeout: 6000,
      };
  }
};
MextradeAdminConfig.unregister = fetchIntercept.register({
  request: function(url, config) {
    config["headers"] = MextradeAdminConfig.buildHeaders(url);
    if (
      config["method"] === "PUT" ||
      config["method"] === "POST" ||
      config["method"] === "DELETE"
    ) {
      const userInformation = authenticationService.userInformation();
      const scheme = authenticationService.scheme();
      if (authenticationService.isAuthenticate() && userInformation) {
        const new_body = config["body"] ? JSON.parse(config["body"]) : {};
        new_body.user_id = userInformation.user.username;
        new_body.platform = scheme.name;
        config["body"] = JSON.stringify(new_body);
      }
    }
    return [url, config];
  },
  requestError: function(error) {
    return Promise.reject(error);
  },
  response: function(response) {
    if (response.status === 401) {
      if (Promise) {
        Promise.reject("Unauthorized");
      }
      authenticationService.logout();
      if (!response.url.includes("login")) {
        window.location.reload();
      }
    }
    return response;
  },
  responseError: function(error) {
    return Promise.reject(error);
  },
});
MextradeAdminConfig.customStyles = function(submitted, elementSelected) {
  const radius = 10;
  return {
    control: (base) => ({
      ...base,
      borderRadius: radius,
      borderColor: !submitted
        ? "#2c699d"
        : submitted && elementSelected
        ? "#2c699d"
        : "#A90329",
      "&:hover": {
        borderColor: !submitted
          ? "#2c699d"
          : submitted && elementSelected
          ? "#2c699d"
          : "#A90329",
      },
    }),
    input: (styles) => ({ ...styles, alignItems: "left" }),
    placeholder: (styles) => ({ ...styles, alignItems: "left" }),
    singleValue: (styles, { data }) => ({ ...styles, alignItems: "left" }),
    multiValue: (styles, { data }) => ({ ...styles, borderRadius: radius }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      ":hover": {
        borderTopRightRadius: radius,
        borderBottomRightRadius: radius,
        backgroundColor: "#FFBDAD",
        color: "#DE350B",
      },
    }),
  };
};
MextradeAdminConfig.isAllowed = function() {
  return authenticationService.isAuthenticate()
    ? authenticationService
        .userInformation()
        .user.associations.find(
          (element) =>
            element.platform.name === authenticationService.scheme().name &&
            element.region.name === authenticationService.region().name
        )
    : false;
};
MextradeAdminConfig.isAllowedTo = (role) => {
  if (authenticationService.isAuthenticate()) {
    const permissions = authenticationService
      .userInformation()
      .user.associations.find(
        (element) =>
          element.platform.name === authenticationService.scheme().name &&
          (element.region.name === authenticationService.region().name ||
            element.region.name === "all")
      ).permissions;
    if (permissions.includes("all:*")) {
      return true;
    }
    const permissionsArray = permissions.split(",");
    return permissions && permissionsArray && permissionsArray.includes(role);
  } else {
    return false;
  }
};
MextradeAdminConfig.isJustAllowedTo = (role) => {
  if (authenticationService.isAuthenticate()) {
    const permissions = authenticationService
      .userInformation()
      .user.associations.find(
        (element) =>
          element.platform.name === authenticationService.scheme().name &&
          element.region.name === authenticationService.region().name
      ).permissions;
    const permissionsArray = permissions.split(",");
    return permissions && permissionsArray && permissionsArray.includes(role);
  } else {
    return false;
  }
};
MextradeAdminConfig.isAdmin = (permissions) => {
  return permissions.split(",").includes("all:*");
};
MextradeAdminConfig.formatNumber = function(number, currencySymbol = "$") {
  if (number) {
    return (
      `${currencySymbol}` +
      number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  } else {
    return `${currencySymbol}0`;
  }
};
MextradeAdminConfig.roundNumber = function(number, decimals, symbol) {
  if (number !== "-" && number !== undefined) {
    return (
      parseFloat((Math.round(number * 100) / 100).toString()).toFixed(
        decimals
      ) + ` ${symbol}`
    );
  } else {
    return `${number}`;
  }
};
MextradeAdminConfig.sort_on = function(array, prop) {
  return array
    .map(function(el, i) {
      return { index: i, value: el[prop].toLowerCase() };
    })
    .sort(function(a, b) {
      if (a.value > b.value) {
        return 1;
      }
      if (a.value < b.value) {
        return -1;
      }
      return 0;
    })
    .map(function(el) {
      return array[el.index];
    });
};
export const config = MextradeAdminConfig;
