import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<div className="App Fade"><App/></div>, document.getElementById("ewmadmin-root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();