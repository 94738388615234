import { USER_INFO } from "../actions";
import { authenticationService } from "../../service/auth.service";

const initialState = {
    token: authenticationService.isAuthenticate() ? authenticationService.userInformation().token : null,
    picture: null,
    user: {
        username: authenticationService.isAuthenticate() ? authenticationService.userInformation().user.username : null,
        email: authenticationService.isAuthenticate() ? authenticationService.userInformation().user.email : null,
        associations: authenticationService.isAuthenticate() ? authenticationService.userInformation().user.associations : null
    },
    scheme: null,
};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
