import { SET_REGION } from "../actions";

const initialState = {
    region: null,
};

export function regionReducer(state = initialState, action) {
    switch (action.type) {
        case SET_REGION:
            return {
                ...state,
                region: action.payload
            };
        default:
            return state;
    }
}
