import { authenticationService } from "../../service/auth.service";

export const SET_REGION = "SET_REGION";

export function requestRegion() {
    return dispatch => {
        return dispatch({
            type: SET_REGION,
            payload: authenticationService.isAuthenticate() ? authenticationService.region() : null
        });
    };
}