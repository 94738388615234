import { config } from '../config/config';
import { authenticationService } from "./auth.service";

const serviceUrl = `${ config.apiUrl }/orders/`;
const logsUrl = `${ config.apiUrl }/orderLogs/`;

async function findAll() {
    const url = serviceUrl + authenticationService.scheme().name;
    const requestOptions = { method: 'GET' };
    const res = await fetch(`${ url }/`, requestOptions);
    return res.json();
}

async function findLogsById(id) {
    const url = logsUrl + id;
    const requestOptions = { method: 'GET' };
    const res = await fetch(`${ url }/`, requestOptions);
    return res.json();
}

async function findById(id) {
    const url = serviceUrl + authenticationService.scheme().name;
    const requestOptions = { method: 'GET' };
    const res = await fetch(`${ url }/${ id }`, requestOptions);
    return res.json();
}

async function save(entity) {
    const url = serviceUrl + authenticationService.scheme().name;
    const requestOptions = { method: 'POST', body: JSON.stringify(entity) };
    const res = await fetch(`${ url }/`, requestOptions);
    return res.json();
}

async function update(entity) {
    const url = serviceUrl + authenticationService.scheme().name;
    const requestOptions = { method: 'PUT', body: JSON.stringify(entity) };
    const res = await fetch(`${ url }/${ entity.id }`, requestOptions);
    return res.json();
}

async function remove(id) {
    const url = serviceUrl + authenticationService.scheme().name;
    const requestOptions = { method: 'DELETE' };
    const res = await fetch(`${ url }/${ id }`, requestOptions);
    return res.json();
}

export default { findAll, findById, save, update, remove, findLogsById }