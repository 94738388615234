import React from "react";
import { Route, Switch } from "react-router-dom";
import { Navigation, Shortcut } from "../../navigation";
import Header from "./Header";
import Footer from "./Footer";
import { routes } from "../../../routes";

class Layout extends React.Component {
    render() {
        return (
            <div>
                <Route render={(props) => {
                  return (
                    <Header location={props.location} />
                  )
                }} />
                <Navigation />
                <div id="main" role="main">
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.component ? (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={props => <route.component {...props} />}
                                />
                            ) : null;
                        })}
                    </Switch>
                </div>
                <Footer />
                <Shortcut />
            </div>
        );
    }
}

export default Layout;
