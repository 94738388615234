import React, { useState } from "react";
import ToggleMenu from "./ToggleMenu";
import { authenticationService } from '../../../service/auth.service'
import { connect } from "react-redux";
//import * as platformActions from "../../../store/actions/platform";
//import * as regionActions from "../../../store/actions/region";
import Select from "react-select";
import { config } from "../../../config/config";

const Header = (props) => {
    const [scheme_selected, setSchemeSelected] = useState(props.scheme ? { value: props.scheme.id, label: props.scheme.name }
        : { value: authenticationService.scheme().id, label: authenticationService.scheme().name });
    const [region_selected, setRegionSelected] = useState(props.region ? { value: props.region.id, label: props.region.name }
        : { value: authenticationService.region().id, label: authenticationService.region().name });
    const submitted = false;

    const onSchemeChange = (selected) => {
        authenticationService.setScheme({ id: selected.value, name: selected.label });
        const { id, name } = authenticationService.scheme();
        setSchemeSelected({ value: id, label: name });
        const region = authenticationService.region();
        setRegionSelected({value: region.id, label: region.name});
        props.requestPlatform();
        props.requestRegion();
    };

    const onRegionChange = (selected) => {
        authenticationService.setRegion({ id: selected.value, name: selected.label });
        const { id, name } = authenticationService.region();
        setRegionSelected({ value: id, label: name });
        props.requestRegion();
    };

    let platforms = [];
    let platformsIds = [];
    //regions will be used and shown when an admin user has assigned 2 or more regions in one platform
    let regions = [];
    if (props.user && props.user.associations) {
        platforms = props.user.associations.map(data => {
            const { id, name } = data.platform;
            return { value: id, label: name };
        });
        //filter platforms to be shown only once in select
        platforms = platforms.filter(data => platformsIds.includes(data.value) ? false : platformsIds.push(data.value));

        regions = props.user.associations.map(data => {
            if (data.platform.id === scheme_selected.value) {
                const { id, name } = data.region;
                return { value: id, label: name };
            } else
                return null;
        });
        //filtering regions to remove null values
        regions = regions.filter(data => data ? true : false);
    }

    return (
        <header id="header">
            <div id="logo-group" className="pull-left">
                <span id="logo">
                    <img src="assets/img/ewm_admin.png" alt="EW-Admin" />
                    {props.user ?
                        <>
                            <div style={{ width: "150px", marginTop: "-8px", marginLeft: "7px" }}>
                                <Select
                                    value={scheme_selected}
                                    options={platforms}
                                    styles={config.customStyles(submitted, scheme_selected)}
                                    onChange={onSchemeChange}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0
                                    })} />
                            </div>
                            {
                                props.location.pathname !== "/" && regions.length > 1 ?
                                    <div style={{ width: "150px", marginTop: "-8px", marginLeft: "7px" }}>
                                        <Select
                                            value={region_selected}
                                            options={regions}
                                            styles={config.customStyles(submitted, region_selected)}
                                            onChange={onRegionChange}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0
                                            })} />
                                    </div>
                                    : null
                            }

                        </>
                        : null
                    }
                </span>
            </div>
            <div className="pull-right" /*pulled right: nav area*/>
                {/* #MOBILE */}
                {/*  Top menu profile link : this shows only when top menu is active */}
                {/*<ul id="mobile-profile-img" className="header-dropdown-list hidden-xs padding-5">
                        <li className="">
                            <a href="#/" className="dropdown-toggle no-margin userdropdown" data-toggle="dropdown">
                                <img src="assets/img/avatars/sunny.png" alt="John Doe" className="online"/>
                            </a>
                            <ul className="dropdown-menu pull-right">
                                <li>
                                    <a href="#/" className="padding-10 padding-top-0 padding-bottom-0">
                                        <i className="fa fa-cog"/> Setting
                                    </a>
                                </li>
                                <li className="divider"/>
                                <li>
                                    <a href="#/views/profile" className="padding-10 padding-top-0 padding-bottom-0">
                                        <i className="fa fa-user"/>
                                        <u>P</u>rofile
                                    </a>
                                </li>
                                <li className="divider"/>
                                <li>
                                    <a href="#/" className="padding-10 padding-top-0 padding-bottom-0"
                                       data-action="toggleShortcut">
                                        <i className="fa fa-arrow-down"/> <u>S</u>hortcut
                                    </a>
                                </li>
                                <li className="divider"/>
                                <li>
                                    <a href="#/" className="padding-10 padding-top-0 padding-bottom-0"
                                       data-action="launchFullscreen">
                                        <i className="fa fa-arrows-alt"/> Full
                                        <u>S</u>creen
                                    </a>
                                </li>
                                <li className="divider"/>
                                <li>
                                    <a href="#/login" className="padding-10 padding-top-5 padding-bottom-5"
                                       data-action="userLogout">
                                        <i className="fa fa-sign-out fa-lg"/>
                                        <strong>
                                            <u>L</u>ogout
                                        </strong>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>*/ }
                {/* logout button */}
                <div id="logout" className="btn-header transparent pull-right">
                    <span>
                        <a href="#/login" title="Sign Out" onClick={authenticationService.logout}
                            data-logout-msg="You can improve your security further after logging out by closing this opened browser">
                            <i className="fa fa-sign-out" />
                        </a>
                    </span>
                </div>
                <ToggleMenu className="btn-header pull-right" />
                {/* search mobile button (this is hidden till mobile view port) */}
                {/*<SearchMobile className="btn-header transparent pull-right"/>*/}
                {/* input: search field */}
                {/*<form action="#/search" className="header-search pull-right">
                        <input id="search-fld" type="text" name="param" placeholder="Search..." autoComplete="off"/>
                        <button type="submit">
                            <i className="fa fa-search"/>
                        </button>
                    </form>*/ }
                {/*<LanguageSelector/>*/}
            </div>
            {/* end pulled right: nav area */}
        </header>
    );
}

const mapStateToProps = (state) => {
    return { ...state.user, ...state.scheme, ...state.region };
};

const mapDispatchToProps = dispatch => {
    return {
        // dispatching plain actions
        requestPlatform: () => dispatch({
            type: "SET_PLATFORM",
            payload: authenticationService.isAuthenticate() ? authenticationService.scheme() : null
        }),
        requestRegion: () => dispatch({
            type: "SET_REGION",
            payload: authenticationService.isAuthenticate() ? authenticationService.region() : null
        })
        // this implementation didn't seem to work.
        // requestPlatform: platformActions.requestPlatform,
        // requestRegion: regionActions.requestRegion
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
//old implementation to handle requestPlatform
//export default connect(mapStateToProps, platformActions)(Header);