import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";

import {
    userReducer,
    platformReducer,
    navigationReducer,
    i18nReducer,
    layoutReducer,
    regionReducer,
} from "./reducers"

import {
    requestUserInfo,
    requestPlatform,
    i18nInit,
    dumpLayoutToStorage,
    handleBodyClasses,
    layoutInit,
    requestRegion,
} from "./actions"


export const rootReducer = combineReducers({
    layout: layoutReducer,
    navigation: navigationReducer,
    user: userReducer,
    scheme: platformReducer,
    i18n: i18nReducer,
    region: regionReducer
});

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(
            thunk,
            handleBodyClasses,
            dumpLayoutToStorage,
        )
    )
);
store.dispatch(layoutInit());
store.dispatch(requestUserInfo());
store.dispatch(requestPlatform());
store.dispatch(requestRegion())
store.dispatch(i18nInit());

export default store;
